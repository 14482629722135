import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import Navbar from "./global/navbar";
import Banner from "./home-components/banner";
import Values from "./about-components/values";
import HomeBlockOne from "./home-components/home-block";
import CallToAction from "./home-components/call-to-action";
import Directories from "./home-components/directories";
import Services from "./services-components/services-card";
import Footer from "./global/footer";
import ModalHome from "./home-components/ModalComponent";
import HomeAbout from "./about-components/block-about";
import Map from "./contact-components/map";
import { Helmet } from "react-helmet";
import Counter from "./about-components/counter";
import Gallery from "./gallery-components/gallery";

const Home_V1 = () => {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div>
      <Helmet>
        <title>Home | {` ${rpdata?.dbPrincipal?.name}`}</title>
        <meta
          name="description"
          content={`${rpdata?.dbAbout?.[0].text.substring(0, 150) + "..."}`}
        />
        <meta
          name={` ${rpdata?.dbPrincipal?.name}`}
          content={` ${rpdata?.dbPrincipal?.name}`}
        ></meta>
        <meta name="robots" content="index,follow" />
        <meta name="googlebot" content="index,follow" />
        <link rel="icon" href={rpdata?.dbPrincipal?.favicon} />
      </Helmet>
      <Navbar />
      <Banner />
      <Values />
      <Counter />
      <HomeBlockOne />
      <CallToAction />
      <HomeAbout />
      <Directories />
      <div className="container">
        <div className="row justify-content-center pd-top-100">
          <div className="col-lg-3 col-md-6 text-center content-directorios">
            <a href="https://images.sherwin-williams.com/content_images/sw-pdf-sherwin-williams-colorc.pdf" target="_blank">
              <img src="https://firebasestorage.googleapis.com/v0/b/gallery-ae580.appspot.com/o/Directorios%2FSW-1.jpg?alt=media&token=288b0d1c-3606-4e99-98a9-5f3539cd0081" alt="Google My Business"></img>
            </a>
          </div>
        </div>
      </div>
      <div className="container">
        <h2 className="text-center pd-top-100">What Our Clients Say</h2>
        <div class="elfsight-app-1b3f2783-e9ef-4acb-93a0-88cbf38a1b9c"></div>
      </div>
      <Services />
      <Gallery />
      <ModalHome />
      <Map />
      <Footer />
    </div>
  );
};

export default Home_V1;
